@tailwind base;
@tailwind components;
@tailwind utilities;

.nightBackground {
  background-image: url('/src/assets/villageNight.png');
}

.dayBackground {
  background-image: url('/src/assets/villageDay.png');
}

.noiseBackground {
  /*background-image: url('/src/assets/noise.png');*/
  background-size: cover;
}

.min-container-height {
  min-height: 200px;
}

@media (min-width: 768px) {
  .min-container-height {
    min-height: 500px;
  }
}

@media (min-width: 1024px) {
  .min-container-height {
    min-height: 680px;
  }
}
